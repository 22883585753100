import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import SelectOISAction from "./SelectOISAction";
import RecentActivityTable from "./RecentActivityTable";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import OISBanner from "./OISBanner";
import { handleCommonBRError } from "./utils";
import { GET_IMPORTS_BASE_URL } from "../constants";

let refreshActivity = true;

export function refreshActivityTable(refresh) {
  refreshActivity = refresh;
}

const StyledOISMain = styled.main`
  display: flex;
`;

const useStyles1 = makeStyles((theme) => ({
  root: {
    margin: 16,
    marginLeft: theme.spacing(2.5),
  },
}));

const checkNeedToRefresh = (recentActivities) => {
  let allActivitiesFinished = recentActivities.every(function (row) {
    return row.status === "finished" || row.status === "failed";
  });

  refreshActivity = !allActivitiesFinished;
};

async function fetchDataAsync(
  setRecentActivityRecords,
  setASAlertMessage,
  setDisplayASAlert
) {
  // You can await here
  if (refreshActivity) {
    // Force not to refresh if already a request is in progress.
    refreshActivity = false;
    axios
      .get(GET_IMPORTS_BASE_URL)
      .then((res) => {
        let recentActivities = res.data;
        checkNeedToRefresh(recentActivities);
        setRecentActivityRecords(recentActivities);
      })
      .catch((err) => {
        // Incase the get import call failed. Make another call.
        refreshActivity = true;
        handleCommonBRError(err, setASAlertMessage, setDisplayASAlert);
      });
  }
}

export default function OISMain(props) {
  const [recentActivityRecords, setRecentActivityRecords] = useState([]);
  const [filterByType, setFilterByType] = useState(null);

  const classes = useStyles1();

  const [displayASAlert, setDisplayASAlert] = useState(false);
  const [ASAlertMessage, setASAlertMessage] = useState(null);

  const handleCloseASAlert = (value) => {
    setDisplayASAlert(false);
    setASAlertMessage(null);
  };

  useEffect(() => {
    fetchDataAsync(
      setRecentActivityRecords,
      setASAlertMessage,
      setDisplayASAlert
    );
    setInterval(
      () =>
        fetchDataAsync(
          setRecentActivityRecords,
          setASAlertMessage,
          setDisplayASAlert
        ),
      20000 // raised to 20 from 10 seconds from 5
    );
  }, []); // Or [] if effect doesn't need props or state

  const callBackFilter = (a) => {
    setFilterByType(a);
  };

  return (
    <Grid item xs={12}>
      <StyledOISMain>
        <div>
          <div>
            <OISBanner
              onClose={handleCloseASAlert}
              open={displayASAlert}
              message={ASAlertMessage}
            />
          </div>
          <div>
            <SelectOISAction callBackFilter={callBackFilter} />
          </div>
          <div className={classes.root}>Recent Activity:</div>
          <div className={classes.root}>
            <RecentActivityTable
              recentActivityRecords={recentActivityRecords}
              filterByType={filterByType}
            />
          </div>
        </div>
      </StyledOISMain>
    </Grid>
  );
}
